<style scoped>
.fontBold {
	font-weight: bolder;
}
</style>

<template>
	<error-container :error="erreur" :warning="warning">
		<div class="container-layout form animated fadeIn p-0">
			<b-row>
				<b-col sm="12" md="12" lg="12" xl="12">
					<h1 :class="[`${!$screen.sm ? 'msin-page-title' : ''}`, { 'is-pwa': $isPwa() }]" class="px-0">
						{{ FormMSG(215, 'Purchase Order Details') }}
					</h1>
					<b-card no-body>
						<b-row class="back-with-title cursor-pointer d-flex align-items-center pb-2 pl-0" :class="{ 'mt-16': $isPwa() }">
							<b-col cols="9">
								<button class="btn-transparent" @click="handleClickBack">
									<component :is="getLucideIcon('ArrowLeft')" color="rgba(6, 38, 62, 0.65)" :size="22" class="icon" />
									<span class="title-back-bolder mx-0">
										{{ caption1 }}
									</span>
								</button>
							</b-col>
							<b-col cols="3">
								<div class="wrap-status d-flex justify-content-end pr-3">
									<div
										:class="`status ${classStatus(curPo.validated)}`"
										style="font-size: 0.825rem; border: 0.005rem solid rgba(226, 230, 235, 0.65)"
									>
										{{ curPo.validatedStatus }}
									</div>
								</div>
							</b-col>
						</b-row>
						<b-card-text class="px-3 pt-4">
							<b-row>
								<b-col cols="12" xl="12" class="px-3">
									<div class="card card-border-blue-light">
										<b-row>
											<b-col md="3">
												<b-form-group :label="FormMSG(98, 'PO Reference')">
													<b-form-input :value="curPo.poReference" disabled />
												</b-form-group>
											</b-col>
											<b-col md="3">
												<b-form-group :label="FormMSG(58, 'Request date')" label-for="spent">
													<b-input-group>
														<b-form-input v-model="curPo.date" disabled type="text" id="spent"></b-form-input>
														<b-input-group-append>
															<b-input-group-text>
																<calendar color="#06263E" opacity="0.85" width="15" height="15" />
															</b-input-group-text>
														</b-input-group-append>
													</b-input-group>
												</b-form-group>
											</b-col>
											<b-col md="3">
												<b-form-group :label="this.getLabel1" label-for="spent">
													<b-input-group>
														<b-form-input v-model="curPo.amountTotal" disabled type="number" id="spent"></b-form-input>
														<b-input-group-append>
															<b-input-group-text>
																<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
															</b-input-group-text>
														</b-input-group-append>
													</b-input-group>
												</b-form-group>
											</b-col>
											<b-col v-if="curPo.images && curPo.images.length > 0" md="3" class="pt-28">
												<b-button block variant="outline-success" @click="showPicture(curPo.images)">
													<component :is="getLucideIcon('Image')" :size="16" />
													{{ `${FormMSG(78, 'View')} ${curPo.images.length} ${FormMSG(79, 'pictures')}` }}
												</b-button>
											</b-col>
										</b-row>
									</div>
									<div class="card card-border-blue-light mt-3">
										<b-row>
											<b-col>
												<CardListBuilder v-if="$screen.width < 992" :items="poDetails" :fields="poItemFields"> </CardListBuilder>
												<b-table
													v-if="$screen.width >= 992"
													:hover="true"
													responsive="sm"
													:items="poDetails"
													style="text-align: center"
													:fields="poItemFields"
													sticky-header="700px"
													:head-variant="hv"
													bordered
													small
												>
												</b-table>
											</b-col>
										</b-row>
										<b-row :class="`${$screen.width <= 576 ? 'footer-fixed' : ''}`">
											<b-col v-if="canEditPo" md="4" sm="12">
												<div class="w-100 d-flex justify-content-center align-items-center">
													<b-button
														variant="outline-primary"
														block
														@click="handleClickEditPO"
														size="md"
														class="d-flex justify-content-center align-items-center"
														:class="`${$screen.width <= 576 ? 'w-100 mb-2' : 'w-75'}`"
													>
														<component :is="getLucideIcon(ICONS.EDIT.name)" :size="20" class="mr-2" />
														<div style="margin-top: 2px">{{ FormMSG(168, 'Edit Purchase Order') }}</div>
													</b-button>
												</div>
											</b-col>
											<b-col md="4" sm="12">
												<div class="w-100 d-flex justify-content-center align-items-center">
													<b-button
														variant="outline-primary"
														block
														@click="downloadXls"
														size="md"
														class="d-flex justify-content-center align-items-center"
														:class="`${$screen.width <= 576 ? 'w-100 mb-2' : 'w-75'}`"
													>
														<component :is="getLucideIcon(ICONS.DOWNLOAD.name)" :size="20" class="mr-2" />
														<div style="margin-top: 2px">{{ FormMSG(222, 'Download this Purchase Order') }}</div>
													</b-button>
												</div>
											</b-col>
											<b-col md="4" sm="12">
												<div class="w-100 d-flex justify-content-center align-items-center">
													<b-button
														variant="outline-primary"
														block
														@click="sendXlsByEmail"
														size="md"
														class="d-flex justify-content-center align-items-center"
														:class="`${$screen.width <= 576 ? 'w-100 mb-2' : 'w-75'}`"
														:disabled="isLoadingSendMail"
													>
														<b-spinner
															v-show="isLoadingSendMail"
															small
															class="mr-2"
															style="color: rgba(205, 154, 22, 1)"
														></b-spinner>
														<component :is="getLucideIcon(ICONS.SEND.name)" :size="20" class="mr-2" />
														<div style="margin-top: 2px">{{ FormMSG(500, 'Send me this purchace order by Email') }}</div>
													</b-button>
												</div>
											</b-col>
										</b-row>
									</div>
								</b-col>
							</b-row>
						</b-card-text>
						<PoService
							v-model="showPoService"
							:edit-data="poSelected"
							for-screen-validation
							@closed="handlePOServiceClosed"
							@submitted="handlePOServiceSubmitted"
							@refresh="handlePOServiceRefresh"
						/>
						<script-export-modal
							v-model="isScriptPoReportOpen"
							:items="scripts"
							:custom="customReport"
							export-type="po"
							@script-export-modal:loading="handleMailSent"
							@script-export-modal:closed="poRerpotOnClosed"
						/>
					</b-card>
				</b-col>
			</b-row>
		</div>
		<b-modal
			header-class="header-class-modal-doc-package"
			:title="FormMSG(21211, 'Success!')"
			class="modal-success"
			v-model="successModal"
			@ok="successModal = false"
			ok-variant="success"
			ok-only
		>
			{{ FormMSG(601, 'The purchace order has been sent to your Email address') }}
		</b-modal>
	</error-container>
</template>

<script>
import CurrencySvg from '@/components/Svg/Currency';
import languageMessages from '@/mixins/languageMessages';
import { store } from '@/store';
import { Calendar, ArrowLeft as Back, Edit2, ArrowLeft } from 'lucide-vue';
import { isNil } from '@/shared/utils';
import { classStatus } from '@/shared/global-status';
import { getTextFromMenuNumberAndMenuValue } from '@/cruds/language.crud';
import moment from 'moment';
import PoService from '@/components/PoService/Main';
import { getValidations } from '@/cruds/validations.crud';
import { getPO, getPoItems } from '@/cruds/po-item.crud';
import globalMixin from '@/mixins/global.mixin';
import ScriptExportModal from '@/components/Export/ScriptExportModal';
import { getScripts } from '@/cruds/script.crud';
import { purchaseOrderReport } from '@/cruds/export.crud';
import { rendCurrency, getFileExtension, formatTableDate } from '~helpers';
import _ from 'lodash';
import { FILENAME_REPORT, OUTPUT_FORMAT_EXPORT } from '@/shared/constants';

export default {
	name: 'PoDetails',
	components: {
		CurrencySvg,
		Calendar,
		Back,
		PoService,
		Edit2,
		ArrowLeft,
		ScriptExportModal
	},
	mixins: [languageMessages, globalMixin],
	computed: {
		canEditPo() {
			return store.canEditPo();
		},
		caption1: function () {
			if (isNil(this.curPo)) return '';

			const cap =
				this.FormMSG(10, 'Purchase order details from') + ' #' + this.curPo.id + ':   ' + this.curPo.fullName + '  - ' + this.curPo.functionName;

			return cap;
		},
		getLabel1() {
			return this.FormMSG(1, 'Total Amount');
		},
		poItemFields() {
			return [
				{
					key: 'id',
					label: this.FormMSG(50, 'Id'),
					formatter: (value) => {
						return '#' + value;
					},
					sortable: true
				},
				{
					key: 'description',
					label: this.FormMSG(51, 'Description'),
					sortable: true
				},
				{
					key: 'costCenterAndCategoryName',
					label: this.FormMSG(52, 'Category'),
					formatter: (value, key, item) => {
						return `${item.categoryCostCenter} - ${item.categoryName}`;
					},
					sortable: true
				},
				{
					key: 'purchaseOrder.departmentName',
					label: this.FormMSG(156, 'Department'),
					formatter: (value) => {
						if (isNil(value)) return '';
						return value;
					},
					sortable: true
				},
				{
					key: 'price',
					label: this.FormMSG(36, 'Unit price'),
					formatter: (v) => rendCurrency(v),
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'quantity',
					label: this.FormMSG(360, 'Quantity'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'priceTotal',
					label: this.FormMSG(394, 'Amount (tax excl.)'),
					formatter: (v) => rendCurrency(v),
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'total',
					label: this.FormMSG(370, 'Total Amount'),
					formatter: (v) => rendCurrency(v),
					sortable: true,
					class: 'text-right'
				}
			];
		}
	},
	data() {
		return {
			erreur: {},
			successModal: false,
			warning: '',
			hv: 'dark',
			curPo: {
				id: 0,
				requestDate: '',
				amount: 0,
				validated: 0,
				validatedStatus: 'Not Submited'
			},
			poDetails: [],
			isLoading: false,
			showPoService: false,
			poSelected: null,
			currentValidationLevel: 0,
			isScriptPoReportOpen: false,
			poTypeId: 8,
			scripts: [],
			isLoadingSendMail: false,
			customReport: null
		};
	},
	methods: {
		showPicture(images) {
			if (isNil(images)) return;

			const list = images.map((img) => ({
				xid: img,
				src: `${process.env.VUE_APP_GQL}/images/${img}`,
				thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
				ext: getFileExtension(img)
			}));

			this.$previewImages({
				images: list,
				focusIndex: 0,
				options: {
					hideLikeDislike: true,
					hideCommentButton: true,
					hideDisLikeButton: true,
					presentationMode: 'doc'
				}
			});
		},
		async handleClickEditPO() {
			this.showPoService = true;
		},
		async sendXlsByEmail() {
			this.isLoadingSendMail = true;
			this.warning = '';
			this.scripts = await getScripts(store.state.myProject.licenseID, store.state.myProject.id, this.poTypeId, true);
			const id = +this.curPo.id;

			if (this.scripts.length === 0) {
				await this.managePoReport(id, 0, true).then(() => {
					this.createToastForMobile(this.FormMSG(2, 'Success'), this.FormMSG(3, 'The document has been sent to your email address.'));
				});
			} else if (this.scripts.length === 1) {
				await this.managePoReport(id, { id: this.scripts[0].id }, true).then(() => {
					this.createToastForMobile(this.FormMSG(2, 'Success'), this.FormMSG(3, 'The document has been sent to your email address.'));
				});
			} else {
				this.isScriptPoReportOpen = true;
				this.customReport = {
					subjectId: id,
					sendEmail: true
				};
			}
		},
		async downloadXls() {
			this.warning = '';
			this.scripts = await getScripts(store.state.myProject.licenseID, store.state.myProject.id, this.poTypeId, true);
			const id = +this.curPo.id;

			if (this.scripts.length === 0) {
				await this.managePoReport(id);
			} else if (this.scripts.length === 1) {
				await this.managePoReport(id, {
					id: this.scripts[0].id,
					fileName: this.scripts[0].customExportFileName,
					ext: this.getExtensionScript(
						OUTPUT_FORMAT_EXPORT.at(this.scripts[0].outputFileFormat),
						this.scripts[0].customExtension,
						getFileExtension(this.scripts[0].fileName)
					)
				});
			} else {
				this.isScriptPoReportOpen = true;
				this.customReport = {
					subjectId: id,
					sendEmail: false
				};
			}
		},
		async getRoleValidation() {
			const validations = await getValidations();
			const userId = store.userID();
			const departmentId = store.state.myProfile.department;

			let haveValidationAllDepartment = null;
			let haveValidationSingleDepartment = null;

			for (let i = 0; i < validations.length; i++) {
				const element = validations[i];
				if (parseInt(element.department, 10) === -1 && parseInt(element.user.id, 10) === userId) {
					haveValidationAllDepartment = element;
				} else if (parseInt(element.user.id, 10) === userId && parseInt(element.department, 10) === departmentId) {
					haveValidationSingleDepartment = element;
				}
			}

			if (!isNil(haveValidationSingleDepartment) && !isNil(haveValidationAllDepartment)) {
				if (haveValidationSingleDepartment.validationLevelPo >= haveValidationAllDepartment.validationLevelPo) {
					this.currentValidationLevel = haveValidationSingleDepartment.validationLevelPo;
				} else {
					this.currentValidationLevel = haveValidationAllDepartment.validationLevelPo;
				}
			} else {
				if (!isNil(haveValidationSingleDepartment)) {
					this.currentValidationLevel = haveValidationSingleDepartment.validationLevelPo;
				} else if (!isNil(haveValidationAllDepartment)) {
					this.currentValidationLevel = haveValidationAllDepartment.validationLevelPo;
				}
			}
		},
		handlePOServiceClosed() {
			this.showPoService = false;
		},
		async handlePOServiceSubmitted(payload) {
			if (payload) {
				await this.reloadData();
				this.showPoService = false;
			}
		},
		async handlePOServiceRefresh(payload) {
			if (payload) {
				await this.reloadData();
			}
		},
		handleClickBack() {
			if (this.$route.query.PO && this.$route.query.PO === '1') {
				this.$router.push({ path: '/budget?activeTabPO=1' });
			} else {
				this.$router.go(-1);
			}
		},
		getCurrency() {
			return store.state.myProfile.currencySymbol;
		},
		async updateTZdataEach(poItemData) {
			// setting row color
			poItemData.validatedStatus = await this.validatedText(poItemData.validated);
			// https://bootstrap-vue.js.org/docs/reference/color-variants
			poItemData.rowVariant = this.validatedColor(poItemData.validated);
			poItemData.date = this.dateToString(poItemData.requestDate);
		},
		async validatedText(validated) {
			let value = null;
			await new Promise((resolve, reject) => {
				resolve(getTextFromMenuNumberAndMenuValue(1008, validated));
				reject(false);
			}).then((data) => {
				value = data;
			});
			return value;
		},
		validatedColor(validated) {
			if (validated == 1) {
				var retval = 'primary';
			} else if (validated == 2) {
				var retval = 'warning';
			} else if (validated == 8) {
				var retval = 'success';
			} else if (validated == 4 || validated == 16) {
				var retval = 'danger';
			} else {
				var retval = 'grey';
			}
			return retval;
		},
		async reloadData() {
			this.isLoading = true;
			const purchaseOrderId = +this.$route.params.id;

			this.poSelected = await getPO(purchaseOrderId);

			await getPoItems(purchaseOrderId)
				.then((records) => {
					this.poDetails = records;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => (this.isLoading = false));
		},
		classStatus(validated) {
			return classStatus(validated);
		},
		dateToString(date) {
			return moment(date).format('DD/MM/YYYY');
		},
		async managePoReport(subjectId, script = { id: 0, fileName: null, ext: 'xlsx' }, sendEmail = false) {
			let fileName = this.getFileNameScript(FILENAME_REPORT.REPORT_PO, script.id, script.fileName);

			await purchaseOrderReport(
				{
					subjectId,
					sendEmail
				},
				script.id
			)
				.then((result) => {
					if (!sendEmail) {
						this.forceDownload(result, fileName + '.' + script.ext);
					}
				})
				.catch((e) => {
					console.error(e);
				})
				.finally(() => (this.isLoadingSendMail = false));
		},
		poRerpotOnClosed() {
			this.isScriptPoReportOpen = false;
		},
		handleMailSent(payload) {
			if (payload) {
				this.isLoadingSendMail = false;
			}
		}
	},
	async created() {
		let curPo = store.getCurPO();
		await this.updateTZdataEach(curPo);
		this.curPo = curPo;

		await this.getRoleValidation();
		await this.reloadData();
	}
};
</script>
<style lang=""></style>
